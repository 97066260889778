/********** Custom configuration **********/
// Cal Poly Colors
$CalPolyGreen: #154734;
$CalPolyGold: #BD8B13;
$StadiumGold: #F8E08E;
$PolyCanyon: #F2C75C;
$DexterGreen: #A4D65E;
$FarmersMarket: #3A913F;
$SkyBlue: #B5E3D8;
$SurfBlue: #5CB8B2;
$Serenity: #D3E3F4;
$MorroBlue: #ABCAE9;
$MissionBeige: #E4E1D1;
$PismoSand: #CAC7A7;
$CoastSage: #B6CCC2;
$Sycamore: #789F90;
$KennedyGray: #8E9089;
$SealGray: #54585A;

/********** Custom configuration **********/
$accent-color: $DexterGreen;

/** background color **/
$app-background-color: #f5f5f5;

/** Top form color **/
$header-background-color: $CalPolyGreen;
$header-text-color: white;

/** Form body color **/
$form-background-color: white;

/** Select button color **/
$select-button-color: #f5f5f5;

/** Submit button color **/
$form-submit-background-color: $CalPolyGreen;
$form-submit-border-color: $CalPolyGreen;
$form-submit-text-color: white;

$form-required-color: #E34850;
$form-error-color: #E34850;

/********** Bootstrap configuration **********/
$danger: #E34850;
