#workflow_form {
  min-width: 480px;
  height: 100%!important;
  margin-right: auto;
  margin-left: auto;
  background: $form-background-color;
  //font-size: 20px;
  box-shadow: 0 10px 20px 5px grey;
  border-radius: 5px;

  @include media-breakpoint-up(md) {
    width: 768px;
  }

  @include media-breakpoint-up(lg) {
    width: 881px;
  }

  &>div {
    padding: 20px 25px;
  }

  .workflow_header {
    background-color: $header-background-color;
    color: $header-text-color;
    text-align: center;

    h1 {
      margin: 0;
    }
  }

  label {
    &.required:after {
      font-family: 'FontAwesome';
      font-weight: normal;
      font-size: .6rem;
      content: "\f069";
      color: $form-required-color;
      margin-left:2px;
      vertical-align: top;
    }
  }

  fieldset {
    border: 1px solid #c2c2c2;
    padding: 10px;
    margin-bottom: 1rem;
    border-radius: 5px;

    legend {
      width: inherit;
      font-size: 1.25rem;
      margin-bottom: 0;
      float: none;
    }
  }

  .compose-options{
    border-radius: 5px;
    border: 1px solid #c2c2c2;
    background-color: #f5f5f5;
    color: #707070;
    padding: 0 20px 10px 20px;
    margin-top: 10px;
    margin-bottom: 0px;

    @include media-breakpoint-up(lg) {
      margin-top: 1rem;
    }

    h2 {
      color: #2d2d2d;
      font-weight: 400;
      font-size: 1.25rem;
      margin: 10px 0;
    }

    .compose-option-section {
      margin-bottom: 5px;
    }

    .compose-option-group {
      margin-bottom: 10px;
    }

    .pdf-protect-section {
      .pass-strength-subtitle {
        font-size: 0.9rem;
        padding: 6px 0;
        margin-bottom: 10px;
      }
      .pass-input {
        input:not([type="checkbox"]) {
          margin-bottom: 10px;
          padding: 5px;
        }
      }
      .password-toggle-label {
        margin: -2px 0 12px 1px;
        label {
          margin: 0;
        }
      }
      .password-error .alert {
        margin: 10px 0 20px 0;
        background: none;
        font-size: 13px;
        color: $form-error-color;
        border: 0;
        padding: 0;
        word-break: break-word;
      }
    }

    .expiration-section {
      .expiration-in-time {
        margin-bottom: 5px;
        position: relative;
      }
      .expiration-in-days {
        margin-bottom: 5px;

        label {
          display: inline-block;
          font-weight: bold;
        }

        .expiration-input {
          width: 70px;
        }

        .expiration-in-days-label {
          font-weight: normal;
          padding-left: 8px;
        }
        .expiration-error-text {
          display: block;
          color: $form-error-color;
        }
      }
    }
    .ancillary-text {
      font-size: .8rem;

      &.hidden {
        margin-bottom: 0;
      }
    }
  }

  #form_submit_button{
    margin-top: 10px;
    font-size: 15px;
    padding: 0 14px;
    line-height: 32px;
    font-weight: 400;
    min-width: 80px;
    width: 100%;
    color: $form-submit-text-color;
    background-color: $form-submit-background-color;
    border-color: $form-submit-border-color;
  }

  #instruction_section {
    white-space: pre-line;
    margin-bottom: 1rem;
  }
}

.form-group {
  margin-bottom: 1rem;
}
