
.alert {
  padding: 15px;
  margin: 0 0 30px 0;
  background-color: $red;
  color: #ffffff;

  border-radius: 4px;
  border: 2px solid $red;

  opacity: 1;
  transition: opacity 0.15s linear;

  ol {
    padding-left: 20px;
    margin:0;

    li {


    }
  }
}
