// Include functions (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";


// Include default variable overrides
@import 'StyleConfig';
@import 'vars';


/** Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets) **/
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";


/** Include any default map overrides **/


/** Include remainder of required parts **/
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";


/** Include bootstrap components **/
/** ~bootstrap/scss/reboot **/
@import "~bootstrap/scss/reboot";

/** ~bootstrap/scss/grid **/
@import "~bootstrap/scss/grid";

/** ~bootstrap/scss/forms **/
@import "~bootstrap/scss/forms";

/** ~bootstrap/scss/buttons **/
@import "~bootstrap/scss/buttons";

/** ~bootstrap/scss/alert **/
@import "~bootstrap/scss/alert";

/** ~bootstrap/scss/transitions **/
@import "~bootstrap/scss/transitions";

/** ~bootstrap/scss/modal **/
@import "~bootstrap/scss/modal";

/** ~bootstrap/scss/spinners **/
@import "~bootstrap/scss/spinners";

/** ~bootstrap/scss/helpers **/
@import "~bootstrap/scss/helpers";

/** ~bootstrap/scss/utilities" **/
// @import "~bootstrap/scss/utilities";

/** ~bootstrap/scss/utilities/api **/
// @import "~bootstrap/scss/utilities/api";


/** Include Application Specific **/
@import 'default';
@import 'workflow-form';
@import 'dialogs';
@import 'errors';
