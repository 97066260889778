body{
  background: $app-background-color;
  font-family: "adobe-clean-n4", "adobe-clean", Helvetica, Arial, sans-serif;
}

.hidden {
  visibility: hidden;
  display:none;
  width: 0;
  height: 0;
}

small, .small {
  font-size: 85%;
}

input::-webkit-input-placeholder {
  font-size: .9rem;
}
